@tailwind base;
@tailwind components;

@tailwind utilities;

@layer utilities {
   /* .logo-parent-social .image-social-no-blur {
   filter: none;
   -webkit-filter: blur(0px);
   -moz-filter: blur(0px);
   -ms-filter: blur(0px);
   filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='0');
   transform: translate3d(0, 0, 0);
   -webkit-transform: translate3d(0, 0, 0);
} */

   @layer components {
      .common-input-style {
         @apply input-border text-black placeholder-[#8C8C8C] rounded-lg focus:outline-none w-full;
      }
   }
   .pb-primary {
      color: #007aff;
   }
   .para-one {
      padding-top: 5px;
      border-radius: 8px;

      font-weight: bold;
      font-size: 16px;
      line-height: 28px;

      letter-spacing: 0.05em;

      color: #17182d;
   }

   .para-two {
      padding-top: 15px;
      padding-bottom: 10px;

      font-weight: 500;
      font-size: 17px;
      line-height: 24px;

      letter-spacing: 0.05em;

      color: #4f4f4f;
   }
   .filter-back {
      background: rgba(224, 224, 224, 0.26);
      border-radius: 8px;
   }
   .border-drop-down {
      border-bottom: 0.5px solid rgba(60, 60, 67, 0.36);
   }
   .bg-cancel {
      background: #e0e0e0;
   }
   .color-playback-base {
      color: #043293;
   }
   .color-playback-base-responsive {
      color: #043293;
      border-bottom: 2px solid #043293;
   }
   .add-member-small {
      margin-right: 5px;
      border-bottom: 2px solid #ff567a;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      color: #ff567a;
   }
   .color-h1 {
      color: #4f4f4f;
   }
   .font-sub {
      font-size: 21px;
   }
   .font-head {
      font-size: 32px;
   }
   .font-17 {
      font-size: 17px;
   }
   .border-20 {
      border-radius: 20px;
   }
   .font-15 {
      font-size: 15px;
   }
   .base-back {
      background: #f6f6f6;
   }
   .base-blue {
      background: #043293;
   }
   .shade-color {
      color: rgba(0, 0, 0, 0.4);
   }
   .pink-cus {
      color: #ff567a;
   }
   .blue-cus {
      color: #043293;
   }
   .color-sign-in {
      border: 1px solid #ff567a;
   }
   .primary-bg {
      background-color: #043293;
   }
   .input-border {
      border: 1px solid #e0e0e0;
   }
   .color-text {
      color: #8c8c8c;
   }
   .singleTextField {
      width: 55px !important;
      height: 55px !important;
      border-radius: 8px;
      font-size: 25px !important;
   }
   .singlexsFiled {
      width: 45px !important;
      height: 45px !important;
      border-radius: 8px;
      font-size: 25px !important;
   }
   .mb-22 {
      margin-bottom: 85px;
   }
   .pb-pri {
      color: #007aff !important;
   }
   .position-custom {
      top: 75px;
      right: 150px;
   }
   .border-bottom-cus {
      border-bottom: 1px dashed #e0e0e0;
   }
   .drop-down {
      background: #ffffff;
      box-shadow: 0px 10px 40px rgba(3, 100, 230, 0.08);
      border-radius: 8px;
   }
   .color-heading {
      color: #17182d;
   }
   .border-line {
      border: 1px solid rgba(189, 189, 189, 0.3);
   }

   .banner-container {
      border-radius: 14px;
      overflow: hidden;
      width: 99%;
      margin: auto;
      margin-bottom: 5px;
      background-image: linear-gradient(to right, #043293, #1e49a3);
   }

   .banner-image {
      background-image: url("images/dashboard/banner (1).svg");
      background-repeat: no-repeat;
      background-size: cover !important;
      width: 101%;
      position: absolute;
   }
   .header-banner {
      font-style: normal;
      font-weight: 600;
      line-height: 21px;
      color: #ffffff;
   }
   .subtitle-banner {
      font-style: normal;
      font-weight: 500;
      line-height: 18px;

      color: #ffffff;
   }
   .button-red-banner {
      background: #ff567a;
      border-radius: 11px;
      width: 145px;
      height: 49px;
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 18px;
      margin-top: 35px;
      margin-right: 80px;
      color: #ffffff;
   }
   .search-input-profile {
      background-image: url("./images/dashboard/glass.svg");
      background-repeat: no-repeat;
      background-position: 10px center;
      padding-left: 30px !important;
      font-style: normal;
      font-weight: normal;
      font-size: 17px !important;
      line-height: 22px;
      letter-spacing: -0.408px;
      color: rgba(60, 60, 67, 0.6);
      background-color: rgba(118, 118, 128, 0.12);
      border-radius: 10px;
      border: none !important;
      width: 343px;
      height: 36px !important;
   }
   .search-input-profile-common {
      background-image: url("./images/dashboard/glass.svg");
      background-repeat: no-repeat;
      background-position: 10px center;
      padding-left: 30px !important;
      font-style: normal;
      font-weight: normal;
      font-size: 15px !important;
      line-height: 22px;
      letter-spacing: -0.408px;
      color: rgba(60, 60, 67, 0.6);
      border-radius: 10px;
      /* border: none !important; */
      border: 1px solid #e0e0e0;
      width: 99.1%;
      height: 36px !important;
   }
   .search-input-profile-shared {
      background-image: url("./images/dashboard/glass.svg");
      background-repeat: no-repeat;
      background-position: 10px center;
      padding-left: 30px !important;
      font-style: normal;
      font-weight: normal;
      font-size: 17px !important;
      line-height: 22px;
      letter-spacing: -0.408px;
      color: rgba(60, 60, 67, 0.6);
      background-color: rgba(118, 118, 128, 0.12);
      border-radius: 10px;
      border: none !important;
      height: 36px !important;
   }

   .search-input-profile-filter {
      background-image: url("./images/dashboard/glass.svg");
      background-repeat: no-repeat;
      background-position: 10px center;
      padding-left: 30px !important;
      font-style: normal;
      font-weight: normal;
      font-size: 17px !important;
      line-height: 22px;
      letter-spacing: -0.408px;
      color: rgba(60, 60, 67, 0.6);
      background-color: rgba(118, 118, 128, 0.12);
      border-radius: 10px;
      border: none !important;
      width: 100%;
      height: 50px !important;
   }

   .post-card {
      background: #ffffff;
      border: 1px solid #f0f6fd;
      box-shadow: 0px 10px 40px rgba(222, 230, 237, 0.4);
      border-radius: 15px;
   }
   .post-card-post {
      background: #ffffff;
      border: 1px solid #f0f6fd;
      box-shadow: 0px 10px 40px rgba(222, 230, 237, 0.4);
   }
   .post-card-image {
      background: #ffffff;
      box-shadow: 0px 10px 40px rgba(222, 230, 237, 0.4);
      border-radius: 15px;
   }

   .filter-popup {
      background: #ffffff;
      border: 1px solid #f0f6fd;
      box-shadow: 0px 10px 40px rgba(222, 230, 237, 0.4);
   }

   .bg-light-blue {
      background-color: rgba(3, 100, 230, 0.058823529411764705);
   }
   .border-care {
      border-top: 0.5px solid rgba(60, 60, 67, 0.36);
   }
   .text-grey-cus {
      color: #969696;
   }
   .time-bg {
      background: rgba(0, 0, 0, 0.4);
   }
   .table-head {
      color: #17182d;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
   }
   .table-data {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;

      color: #000000;
   }
   .border-shared-patient {
      border-bottom: 1px solid #e0e0e0;
   }
   .div-mention-content {
      color: #043293 !important;
   }
   .photo-upload {
      background: #f4f9ff;
      border: 1px dashed #ced4da;
      box-sizing: border-box;
      border-radius: 5px;
   }
   .color-photo-in {
      color: #ced4da;
   }
}

@font-face {
   font-family: sfProTextRegular;
   src: url("./css/font/SF-Pro-Text-Regular.otf");
   font-weight: 400;
   font-style: normal;
}

body {
   font-family: sfProTextRegular, Poppins;
   background-color: #f6f6f6;
}
.th {
   color: #17182d;
   font-weight: 600;
   font-size: 16px;
   line-height: 19px;
   color: #17182d;
}
.custom-head {
   background: rgba(224, 224, 224, 0.2);
}
progress {
   border-radius: 7px;

   border: 1px solid #e0e0e0;
}
progress::-webkit-progress-bar {
   background-color: white;
   border-radius: 7px;
}
progress::-webkit-progress-value {
   background-color: #043293;
   border-radius: 7px;
}

.Toastify__toast-body {
   color: #444c63;
   font-size: 16px;
   min-height: 70px !important;
   border-radius: 18px !important;
   background-color: white;
   box-shadow: 0px 10px 40px rgba(3, 100, 230, 0.08);
}
.Toastify__toast > button > svg {
   display: none;
}
.Toastify__toast {
   overflow: visible !important;
   box-shadow: 0 0 0 transparent !important;
}
.Toastify__toast--default {
   background: transparent !important;
}
.iframe-pdf {
   width: 1px;
   min-width: 100%;
   width: 100%;
   height: 100%;
}

.switch {
   position: relative;
   display: inline-block;
   width: 50px;
   height: 30px;
}

.switch input {
   opacity: 0;
   width: 0;
   height: 0;
}

.slider {
   position: absolute;
   cursor: pointer;
   top: -1px;
   left: 0;
   right: -9px;
   bottom: 0;
   background-color: #ccc;
   -webkit-transition: 0.4s;
   transition: 0.4s;
}

.slider:before {
   position: absolute;
   content: "";
   height: 24px;
   width: 26px;
   left: 4px;
   bottom: 4px;
   background-color: white;
   -webkit-transition: 0.4s;
   transition: 0.4s;
}

input:checked + .slider {
   background-color: #043293;
}

input:focus + .slider {
   box-shadow: 0 0 1px #043293;
}

input:checked + .slider:before {
   -webkit-transform: translateX(26px);
   -ms-transform: translateX(26px);
   transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
   border-radius: 34px;
}

.slider.round:before {
   border-radius: 50%;
}

.healthcare-facility::-webkit-scrollbar {
   width: 4px;
}

.small-frame {
   margin-bottom: 15px;
   /* animation: fadeIn 3s;
   -webkit-animation: fadeIn 3s;
   -moz-animation: fadeIn 3s;
   -o-animation: fadeIn 3s;
   -ms-animation: fadeIn 3s; */
   animation: iconToTop 3s;
}
.patient-circle-one {
   display: flex;
   justify-content: center;
   align-items: center;
   background-image: url("images/login/circle1.png");
   background-repeat: no-repeat;
   position: fixed;
   /* position: absolute; */
   top: 10%;
   left: 15%;
   height: 56px;
   width: 56px;
}
.patient-circle-two {
   display: flex;
   justify-content: center;
   align-items: center;
   background-image: url("images/login/circle2.png");
   background-repeat: no-repeat;
   position: fixed;
   width: 132px;
   height: 132px;
   top: 15%;
   right: -10%;
}
.patient-circle-three {
   display: flex;
   justify-content: center;
   align-items: center;
   background-image: url("images/login/circle3.png");
   background-repeat: no-repeat;
   position: fixed;
   width: 679px;
   height: 342px;
   bottom: -22%;
   left: -53%;
}
.scroll-custom {
   overflow-y: auto !important;
}

@keyframes iconToTop {
   from {
      height: 320px;
   }
   to {
      height: 50px;
   }
}

/* @media (prefers-color-scheme: dark) {
   body,
   html,
   root,
   div,
   input,
   button,
   span {
      background-color: black;
      color: #fff;
   }
} */
